import React from 'react'
import vg from "../assets/2.webp";
import {AiFillGoogleCircle,
  AiFillAmazonCircle,
  AiFillYoutubeoutube,
  AiFillInstagram,
  AiFillYoutube
} from "react-icons/ai";

function Home() {
  return (
    <>
    <div className="home" id="home">
      <main>
        <h1>TechyStar</h1>
        <p>Solution to all your Problems</p>
      </main>
       </div>

       <div className="home2">
       <img src={vg} alt="Graphics" />
       <div>
        <p>

        We are your one and only solution to the tech problems you face
            every day. We are leading tech company whose aim is to increase the
            problem solving ability in children.
        </p>

       </div>

       </div>

       <div className="home3" id="about">
        <div>
          <h1>Who we are?</h1>
          <p>At TechyStar, we're not just a team - we're a community united by our love for all things tech. From coding wizards to design mavens, our diverse talents converge to craft digital experiences that inspire and delight. With a relentless pursuit of innovation and a keen eye for detail, we're committed to pushing boundaries and setting new standards in the tech world. Whether you're a startup seeking to disrupt the market or an established enterprise looking to stay ahead of the curve, we're here to empower your vision and propel your success. Together, let's unlock the full potential of technology and shape a brighter future for generations to come.</p>
        </div>


       </div>
       <div className="home4"   id="brands">

        <div>
          <h1 >Brands</h1>
          <article>
            <div style={{
              animationDelay:"0.3s",
            }}>
              <AiFillGoogleCircle/>
              <p>Google</p>
            </div>
            <div style={{
              animationDelay:"0.5s",
            }}>
              <AiFillAmazonCircle/>
              <p>Amazon</p>
            </div>
            <div style={{
              animationDelay:"0.7s",
            }}>
              <AiFillYoutube/>
              <p>Youtube</p>
            </div>
            <div style={{
              animationDelay:"1s",
            }}>
              <AiFillInstagram/>
              <p>Instagram</p>
            </div>
          </article>
        </div>
       </div>
    
    
    
    </>
  )
}

export default Home